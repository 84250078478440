import Vue from 'vue'
import { EagleListConfigInterface, TableDataType } from '@/components/list/types/list'
import siteAdmin from "modules/siteAdmin";
class listConfig {
  protected vm?: Vue
  protected listKey?: string

  get(vm: Vue, listKey: string) : EagleListConfigInterface {
    this.vm = vm
    this.listKey = listKey

    return {
      pageTitle: 'module.site_admin_log',
      search: 'site_admin_log.search_keyword',
      filter: {
        site_admin_id: {
          label: 'module.site_admin',
          type: 'selector',
          // @ts-ignore
          selector: this.vm?.$siteAdminSelector,
          selectorApplyCallback: data => {
            if(!data) return null
            return {
              id: data.id,
              name: data.name,
            }
          },
          dataText: 'name',
          dataValue: 'id',
          indexFilterValue: filterValue => !filterValue ? null : filterValue.id,
        },
        created_at: {
          label: 'data.created_at',
          type: 'date',
        },
      },
      selectedData: row => ({
        id: row.id,
      }),
      displayMode: 'table',
      sort: [
        { label: 'data.created_at', key: 'created_at' },
      ],
      table: {
        data: <TableDataType>[
          {
            key: 'site_admin_id',
            label: 'module.site_admin',
            component: () => import('./siteAdminLogList/siteAdmin.vue'),
            maxWidth: '200px',
          },
          {
            key: 'action',
            label: 'site_admin_log.data.action',
            type: 'text',
            text: row => this.vm?.$t(`site_admin_log.data.action.${row.action}`),
            maxWidth: '180px',
          },
          {
            key: 'ip',
            label: 'data.ip',
            type: 'text',
            copyable: true,
          },
          {
            key: 'target',
            label: 'site_admin_log.data.target',
            type: 'text',
          },
          {
            key: 'created_at',
            label: 'data.created_at',
            type: 'time',
          },
        ],
      },
      batch: {
        delete: {
          contentEmpty: true,
        },
      },
      metaAction: {
        create: {
          label: 'action.create',
          linkTarget: '_self',
          type: 'route',
          route: () => ({ name: 'site-admin-log-create' }),
        },
      },
      dataAction: {
        view: {
          label: 'action.view',
          color: 'green',
          buttonProperties: () => ({
            class: 'white--text',
          }),
          callback: row => {
            this.vm?.$apopup.base({
              title: this.vm?.$t('action.view'),
              siteAdminLog: row,
              width: '600px',
              bodySlot: () => import('./siteAdminLogList/siteAdminLogDetail.vue'),
            })
          },
        },
        delete: {
          removeTarget: row => row.name,
        },
      },
    }

  }
}

export default new listConfig()
